import {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import {AnyArrayOf} from '~@core/type/Common';
import CommonUtil from '~@core/util/CommonUtil';
import IdUtil from '~@core/util/IdUtil';

export function useDeepEffect(fn, deps) {
  const depsRef = useRef<unknown[]>(null)

  return useEffect(() => {
    let isChangedDeps = true;
    const prevDeps = depsRef.current;
    if (prevDeps) {
      isChangedDeps = deps.some((dep, index) => !_.isEqual(dep, prevDeps[index]));
    }
    if (isChangedDeps) {
      fn()
      depsRef.current = deps
    }
  }, deps)
}

export function useDeepAsyncEffect(fn: <T>() => Promise<T | void>, deps) {
  return useDeepEffect(() => {
    fn();
  }, deps);
}

export function usePrevEffect(fn, deps) {
  const depsRef = useRef<unknown[]>(null)

  return useEffect(() => {
    const prevDeps = depsRef.current || _.times((deps || []).length, _.constant(null));
    fn(prevDeps)
    depsRef.current = deps
  }, deps)
}

export function useAsyncEffect(fn: <T>() => Promise<T | void>, deps) {
  return useEffect(() => {
    fn();
  }, deps);
}

export function useDebounceDeepEffect(fn, deps, time = 300) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const debounceFn = useMemo(() => _.debounce(() => {
    if (fnRef.current) {
      const theFn = fnRef.current;
      theFn();
    }
  }, time), []);

  return useDeepEffect(debounceFn, deps);
}

export function useDebounceEffect(fn, deps, time = 300, options: {
  leading?: boolean | undefined;
  maxWait?: number | undefined;
  trailing?: boolean | undefined;
} = {
  leading: true
}) {
  const fnRef = useRef(fn);
  const debounceFn = useMemo(() => _.debounce(() => {
    if (fnRef.current) {
      const theFn = fnRef.current;
      theFn();
    }
  }, time, options), []);

  return useEffect(debounceFn, deps);
}

export function useDebounce(fn, deps, time = 500) {
  const debounceFn = useMemo(() => _.debounce(fn, time), deps);
  return debounceFn;
}

export function useAsyncDebounce(fn: <T>(...arg) => Promise<T | void>, deps) {
  return useDebounce((...arg2) => {
    fn(...arg2);
  }, deps);
}

export function usePrevious<T>(value) {
  const ref = useRef<T>(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useLastChange<T>(value) {
  const ref = useRef<AnyArrayOf<T>>(null);
  if (!ref.current) {
    ref.current = [value, null];
  } else if (ref.current[0] !== value) {
    ref.current[1] = ref.current[0];
    ref.current[0] = value;
  }
  return _.get(ref.current, 1);
}

export function useUnmount(fn) {
  return useEffect(() => fn, []);
}

export function useNext(deps?: unknown[]) {
  const ref = useRef<{
    sign: boolean,
    callbacks: unknown[]
  }>({
    sign: false,
    callbacks: []
  });
  useEffect(() => {
    if (ref.current?.sign) {
      ref.current.sign = false;
      _.each(ref.current.callbacks, (e) => {
        CommonUtil.invoke(e);
      })
      ref.current.callbacks = []
    }
  }, deps)
  return function next(callback) {
    ref.current.callbacks.push(callback);
    ref.current.sign = true;
  }
}

export interface ObjectStateHolder<T> {
  value?: T,
  state: string
}

export function useObjectState<S>(value?: S): [ObjectStateHolder<S>, Dispatch<SetStateAction<S | undefined>>] {
  const [instance, setInstance] = useState<ObjectStateHolder<S>>({
    value,
    state: IdUtil.uuid4()
  })
  return [instance, (value: SetStateAction<S | undefined>) => {
    setInstance((prevState) => {
      const newValue = CommonUtil.invoke(value, prevState?.value);
      return {
        value: newValue,
        state: IdUtil.uuid4()
      } as ObjectStateHolder<S>
    })
  }]
}

export function useEffectAll(fn, deps) {
  const prevDeps = usePrevious<unknown[]>(deps);
  const changeTarget = useRef<unknown[]>(null);

  useEffect(() => {
    // nothing to compare to yet
    if (changeTarget.current === undefined) {
      changeTarget.current = prevDeps;
    }

    // we're mounting, so call the callback
    if (changeTarget.current === undefined) {
      return fn();
    }

    // make sure every dependency has changed
    if (changeTarget.current?.every((dep, i) => dep !== deps[i])) {
      changeTarget.current = deps;
      return fn();
    }

    return undefined
  }, [fn, prevDeps, deps]);
}